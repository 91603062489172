.ql-clipboard {
    position: fixed;
  }

  .ql-toolbar { 
    position: absolute; 
    top: 0;
    left:0;
    right:0; 
    margin-left: 15px; 
    margin-right: 15px; 
    margin-top: 9px;
  }
  