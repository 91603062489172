/*  
*** Aside Overrides
*/
.aside.aside-dark {
    background-color: #0f0f2e;
}
.aside.aside-dark .aside-logo {
    background-color: #12123d;
}
.aside-dark .menu .menu-item .menu-link:hover:not(.disabled):not(.active),
.aside-dark .menu .menu-item .menu-link.active,
.aside-dark .menu .menu-item.hover > .menu-link:not(.disabled):not(.active), .aside-dark .menu .menu-item .menu-link:hover:not(.disabled):not(.active) {
    background-color: #12123d;
}
.aside-dark .menu .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon i,
.aside-dark .menu .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon i,
.aside-dark .menu .menu-item .menu-link.active .menu-icon i {
  color: #147bd1;
}
.aside-dark .menu .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon .svg-icon svg [fill]:not(.permanent):not(g),
.aside-dark .menu .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon .svg-icon svg [fill]:not(.permanent):not(g),
.aside-dark .menu .menu-item .menu-link.active .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  fill: #147bd1;
}
/* 
*** Card settings
*/
.card .card-settings {
    opacity: 0.2;
    transition: opacity 0.3s;
}
.card:hover .card-settings { opacity: 1; }
.card-settings .btn.btn-icon.card-options-active i {
    color: var(--bs-blue);
}
.card-label{
    margin:0;
    padding:0;
    border:0;
    text-transform: capitalize;
}
.card__draggable-handle {
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}
/*
*** Custom behavior for hidden/sortable fields
*/
/*  draggable for tables */
table tbody.draggable-area tr.draggable td.draggable-handle {
    opacity: 0;
    transition: opacity 0.3s;
}
table tbody.draggable-area tr.draggable:hover td.draggable-handle { opacity: 0.5; }
table tbody.draggable-area tr.draggable:hover td.draggable-handle:hover { opacity: 1; }
/*  sorting/visibility for field */
.has-card-options .field-options {
    display: none;
}
.has-card-options.card-options-active .card-field .field-options,
//.has-card-options.card-options-active .card-field,
.has-card-options.card-options-active .card-field.card-field-hidden {
    display: block !important;
    opacity: 1 !important;
}
.has-card-options .card-field {
    opacity: 1;
    transition: opacity 0.3s;
    position: relative;
}
.has-card-options .card-field.card-field-hidden {
    opacity: 0;
}
.card-body.sortable-zone .sortable.draggable--over { 
    opacity: 0.2 !important;
    background-color: var(--bs-secondary);
}
/*  field option styling */
.field-options {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: rgba(255,255,255,0.3);
    text-align: right;
}
.field-options > * { 
    position: absolute;
    top: -12px;
}
.field-options > .form-icon { right: 30px; }
.field-options > .sortable-handle { 
    right: 0; 
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}
.field-options .form-icon { cursor: pointer; }
.field-options .form-icon input[type="checkbox"] {  display: none; }
.field-options .form-icon input[type="checkbox"]+label i.form-icon--active,
.field-options .form-icon input[type="checkbox"]:checked+label i.form-icon--inactive {
    display: none;
} 
.field-options .form-icon input[type="checkbox"]+label i.form-icon--inactive,
.field-options .form-icon input[type="checkbox"]:checked+label i.form-icon--active {
    display: block;
}
.field-options .form-icon input[type="checkbox"]+label i.form-icon--active {
    color: var(--bs-blue);
}
/* 
*   autonom dynamic card 
*/
.autonom-dynamic-card {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.autonom-dynamic-card > div {
    flex: 0 1 auto;
    width: 100%;
    padding: 15px;
}
@media screen and (min-width:568px) {
    .autonom-dynamic-card > div {
        width: 50%;
    }
}
@media screen and (min-width:992px) {
    .autonom-dynamic-card > div {
        width: 33.33%;
    }
}
@media screen and (min-width:1200px) {
    .autonom-dynamic-card > div {
        width: 25%;
    }
}
@media screen and (min-width:1600px) {
    .autonom-dynamic-card > div {
        width: 20%;
    }
}

/*  temp menu */
.menu .menu-sub .menu-item .menu-link {
    padding-top: 0.50rem;
    padding-bottom: 0.50rem;
}

.page__top-buttons {
    display: flex;
    margin-bottom: 20px;
}

.page__top-buttons .item-right {
    margin-left: auto;
}

.template-name-form-group {
    margin-right: auto;
}

.modal-xxl {
    max-width: 800px !important;
    // width: 900px !important;
}