

.full-map {
    margin: -30px;
    background: #000;
    height: 60vh;
    display: grid;
    font-size: 90px;
    color: #f2f2f2;
    font-weight: 900;
    border-radius: 0px 0px 12px 12px;
}

.car-number-plate {
    font-family: "DIN 1451 Std Mittelschrift";
    outline: 1px solid #000;
    padding: 1px 4px 2px 13px;
    border-radius: 2px;
    color: #000 !important;
    word-break: keep-all;
    white-space: nowrap;
    position: relative;
    font-size: 12px !important;
}
.car-number-plate::before {
    content: "RO";
    padding: 8px 2px 5px 2px;
    border-radius: 0px;
    color: #fff !important;
    font-size: 6px;
    background: #1868e3;
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 100%;
}

.car-brand {
    &:hover {
        cursor:pointer;
    }
}

.car-badge {
    background:#eee;
    margin-right: 5px;
    padding: 2px 12px;
    border-radius: 4px;
    font-size: 13px;
}
.email-icon-wrapper {
    .svg-icon.svg-icon-4 svg {
        width: 90px !important;
        height: 90px !important;
        max-height: unset !important;
    }
}