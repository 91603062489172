.photoModal .modal-content {
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

.addDocumentModal .modal-dialog {
  width: 60%;
  max-width: none !important
}

@media screen and (max-width:568px) {
  .addDocumentModal .modal-dialog {
    width: 97%;
    margin-top: 4%;
  }
}

.clickable-grow-fade-out {
  opacity: 1;
  transition: 0.3s;
  transition-timing-function: ease-out;
}

.clickable-grow-fade-out:hover {
  opacity: 0.65;
  transform: scale(1.15);
  cursor: pointer;
}

.modalContainer {
  position: fixed;
  width: 100vw;
  height: 100%;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
}

​ .modal_style {
  width: 90%;
  max-width: 500px;
  border: 5px;
  padding: 10px;
  background-color: white;
  box-sizing: border-box;
}

​ .modal__bottom {
  text-align: right;
  margin-top: 10px;
}

​ .modal__bottom button {
  padding: 6px 10px;
}

.sigPadContainer {
  background-color: rgb(238, 235, 235);
  padding: 0 10px 10px;
}

.sigCanvas {
  width: 100%;
  height: 200px;
}

.sigPadContainer hr {
  margin-bottom: unset;
  background-color: gainsboro;
}

.sigPadContainer button {
  border: none;
  margin-left: auto;
  color: rgb(78, 75, 75);
  padding: 0;
  display: block;
  margin-top: 5px;
}

.sigPadContainer button:hover {
  background-color: unset;
  color: black;
}

.sigPad__penColors {
  margin-bottom: 10px;
}

​ .sigPad__penColors p {
  display: inline-block;
  margin-right: 5px;
}

​ .sigPad__penColors span {
  padding: 0px 9px;
  border-radius: 100%;
  margin-right: 5px;
}

.modal__bottom .create {
  margin-left: 10px;
  background-color: rgb(126, 119, 119);
  color: white;
}

​ .signature {
  width: 200px;
  height: 70px;
  margin-top: 20px;
  box-shadow: 0 0 4px 2px gainsboro;
  padding: 10px;
  object-fit: contain;
}

.addPvContainer .row{
  margin-bottom: 35px;
}

.addPvContainer .col .btn{
  margin-top:25px;
  width: 100%;
  height: 55%;
}

.pdfContainer{
  display: flex;
  flex-direction: column;
  margin-right: 5%;
}

.pdfContainer > p{
  font-size: 14px;
  font-weight: 600;
  color: #0095E8;
  margin-left: 25%;
  margin-bottom: 6px;
}

.iconsContainer{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 23%;
}

.iconsContainer > div{
  background-color: #F5F8FA;
  width: 29px;
  height: auto;
  padding: 3px;
  align-content: center;
  border-radius: 0.475rem;
}

.svg{
  font-size: 20px;
}

.plusIcon{
  margin-right: 0px !important;
}