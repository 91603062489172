//
// Theme style
//

// Mode
$mode: default;

// Initialize
@import 'init';

// Components
@import './core/components/components';
@import 'components/components';

// Layout
@import 'layout/layout';
@import './core/layout/docs/layout';

// Pages
@import 'pages.scss';

.custom-table {
  .table {
    border: 1px solid #ddd !important;

    .tr {
      :last-child {
        .td {
          border-bottom: 0 !important;
        }
      }
    }

    .th,
    .td {
      padding: 5px;
      border-bottom: 1px solid #ddd !important;
      border-right: 1px solid #ddd !important;
      background-color: #fff !important;
      overflow: hidden !important;

      :last-child {
        border-right: 0 !important;
      }
    }

    &.sticky {
      overflow: scroll !important;
      .header,
      .footer {
        position: sticky !important;
        z-index: 1 !important;
        width: fit-content !important;
      }

      .header {
        top: 0 !important;
        box-shadow: 0px 3px 3px #ccc !important;
      }

      .footer {
        bottom: 0 !important;
        box-shadow: 0px -3px 3px #ccc !important;
      }

      .body {
        position: relative !important;
        z-index: 0 !important;
      }

      [data-sticky-td] {
        position: sticky !important;
      }

      [data-sticky-last-left-td] {
        box-shadow: 2px 0px 3px #ccc !important;
      }

      [data-sticky-first-right-td] {
        box-shadow: -2px 0px 3px #ccc !important;
      }
    }
  }
}
