#root {
  min-height: 100%;
}

.container-form-sms-campaign {
  height: 100%;
  .wpcf7 .screen-reader-response {
    position: absolute;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    word-wrap: normal !important;
  }

  .wpcf7 form .wpcf7-response-output {
    margin: 2em 0.5em 1em;
    padding: 0.2em 1em;
    border: 2px solid #00a0d2; /* Blue */
  }

  .wpcf7 form.init .wpcf7-response-output,
  .wpcf7 form.resetting .wpcf7-response-output,
  .wpcf7 form.submitting .wpcf7-response-output {
    display: none;
  }

  .wpcf7 form.sent .wpcf7-response-output {
    border-color: #46b450; /* Green */
  }

  .wpcf7 form.failed .wpcf7-response-output,
  .wpcf7 form.aborted .wpcf7-response-output {
    border-color: #dc3232; /* Red */
  }

  .wpcf7 form.spam .wpcf7-response-output {
    border-color: #f56e28; /* Orange */
  }

  .wpcf7 form.invalid .wpcf7-response-output,
  .wpcf7 form.unaccepted .wpcf7-response-output,
  .wpcf7 form.payment-required .wpcf7-response-output {
    border-color: #ffb900; /* Yellow */
  }

  .wpcf7-form-control-wrap {
    position: relative;
  }

  .wpcf7-not-valid-tip {
    color: #dc3232; /* Red */
    font-size: 1em;
    font-weight: normal;
    display: block;
  }

  .use-floating-validation-tip .wpcf7-not-valid-tip {
    position: relative;
    top: -2ex;
    left: 1em;
    z-index: 100;
    border: 1px solid #dc3232;
    background: #fff;
    padding: 0.2em 0.8em;
    width: 24em;
  }

  .wpcf7-list-item {
    display: inline-block;
    margin: 0 0 0 1em;
  }

  .wpcf7-list-item-label::before,
  .wpcf7-list-item-label::after {
    content: ' ';
  }

  .wpcf7-spinner {
    visibility: hidden;
    display: inline-block;
    background-color: #23282d; /* Dark Gray 800 */
    opacity: 0.75;
    width: 24px;
    height: 24px;
    border: none;
    border-radius: 100%;
    padding: 0;
    margin: 0 24px;
    position: relative;
  }

  form.submitting .wpcf7-spinner {
    visibility: visible;
  }

  .wpcf7-spinner::before {
    content: '';
    position: absolute;
    background-color: #fbfbfc; /* Light Gray 100 */
    top: 4px;
    left: 4px;
    width: 6px;
    height: 6px;
    border: none;
    border-radius: 100%;
    transform-origin: 8px 8px;
    animation-name: spin;
    animation-duration: 1000ms;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  @media (prefers-reduced-motion: reduce) {
    .wpcf7-spinner::before {
      animation-name: blink;
      animation-duration: 2000ms;
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  @keyframes blink {
    from {
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }

  .wpcf7 input[type='file'] {
    cursor: pointer;
  }

  .wpcf7 input[type='file']:disabled {
    cursor: default;
  }

  .wpcf7 .wpcf7-submit:disabled {
    cursor: not-allowed;
  }

  .wpcf7 input[type='url'],
  .wpcf7 input[type='email'],
  .wpcf7 input[type='tel'] {
    direction: ltr;
  }

  .wpcf7-reflection > output {
    display: list-item;
    list-style: none;
  }

  .wpcf7-reflection > output[hidden] {
    display: none;
  }

  /* initially hide all groups (even before JS is loaded), so the page will never render them while loading */
  [data-class='wpcf7cf_group'],
  .wpcf7cf_remove,
  .wpcf7cf_add {
    display: none;
  }

  .wpcf7cf_repeater_sub {
    margin-bottom: 20px;
  }

  .wpcf7cf_repeater_controls,
  .wpcf7cf_step_controls {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 20px;
  }

  .wpcf7cf_multistep .wpcf7cf_step {
    /* display:none; */
    width: 100%;
  }

  .wpcf7cf_multistep .wpcf7cf_step .step-title {
    display: none;
  }

  .wpcf7cf_multistep .wpcf7cf_steps-dots {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    flex-wrap: wrap;
  }

  .wpcf7cf_multistep .wpcf7cf_steps-dots .dot .step-index {
    display: inline-block;
    border-radius: 50%;
    background: #dfdfdf;
    color: #000000;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
  }

  .wpcf7cf_multistep .wpcf7cf_steps-dots .dot {
    border-bottom: 5px solid #dfdfdf;
    text-align: center;
    flex: 1;
    padding: 15px;
    min-width: 120px;
  }
  .wpcf7cf_multistep .wpcf7cf_steps-dots .dot.completed {
    border-bottom: 5px solid #333;
  }
  .wpcf7cf_multistep .wpcf7cf_steps-dots .dot.active {
    border-bottom: 5px solid #333;
    font-weight: bold;
  }

  .wpcf7cf_multistep .wpcf7cf_steps-dots .dot.completed .step-index {
    background-color: #333;
    color: #ffffff;
  }

  .wpcf7cf_multistep .wpcf7cf_steps-dots .dot.active .step-index {
    background-color: #333;
    color: #ffffff;
  }

  .wpcf7cf_step_controls .disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.5;
  }
  .intl-tel-input {
    position: relative;
    /*display:inline-block*/
  }

  .intl-tel-input * {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
  }

  .intl-tel-input .hide {
    display: none;
  }

  .intl-tel-input .v-hide {
    visibility: hidden;
  }

  .intl-tel-input input,
  .intl-tel-input input[type='tel'],
  .intl-tel-input input[type='text'] {
    position: relative;
    z-index: 0;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-right: 36px;
    margin-right: 0;
  }

  .intl-tel-input .flag-container {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    padding: 1px;
  }

  .intl-tel-input .selected-flag {
    z-index: 1;
    position: relative;
    width: 36px;
    height: 100%;
    padding: 0 0 0 50%;
  }

  .intl-tel-input .selected-flag .iti-flag {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .intl-tel-input .selected-flag .iti-arrow {
    position: absolute;
    top: 50%;
    margin-top: -2px;
    right: 6px;
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 4px solid #555;
  }

  .intl-tel-input .selected-flag .iti-arrow.up {
    border-top: none;
    border-bottom: 4px solid #555;
  }

  .intl-tel-input ul.country-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .intl-tel-input .country-list {
    position: absolute;
    z-index: 2;
    list-style: none;
    text-align: left;
    padding: 0;
    margin: 0 0 0 -1px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    border: 1px solid #ccc;
    white-space: nowrap;
    max-height: 200px;
    overflow-y: scroll;
  }

  .intl-tel-input .country-list.dropup {
    bottom: 100%;
    margin-bottom: -1px;
  }

  .intl-tel-input .country-list .flag-box {
    display: inline-block;
    width: 20px;
  }

  @media (max-width: 500px) {
    .intl-tel-input .country-list {
      white-space: normal;
    }
  }

  .intl-tel-input .country-list .divider {
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid #ccc;
    list-style: none;
  }

  .intl-tel-input .country-list li.country {
    list-style: none;
  }

  .intl-tel-input .country-list .country {
    padding: 5px 10px;
  }

  .intl-tel-input .country-list .country .dial-code {
    color: #999;
  }

  .intl-tel-input .country-list .country.highlight {
    background-color: rgba(0, 0, 0, 0.05);
  }

  .intl-tel-input .country-list .country-name,
  .intl-tel-input .country-list .dial-code,
  .intl-tel-input .country-list .flag-box {
    vertical-align: middle;
  }

  .intl-tel-input .country-list .country-name,
  .intl-tel-input .country-list .flag-box {
    margin-right: 6px;
  }

  .intl-tel-input.allow-dropdown input,
  .intl-tel-input.allow-dropdown input[type='tel'],
  .intl-tel-input.allow-dropdown input[type='text'],
  .intl-tel-input.separate-dial-code input,
  .intl-tel-input.separate-dial-code input[type='tel'],
  .intl-tel-input.separate-dial-code input[type='text'] {
    padding-right: 6px;
    padding-left: 52px;
    margin-left: 0;
  }

  .intl-tel-input.allow-dropdown .flag-container,
  .intl-tel-input.separate-dial-code .flag-container {
    right: auto;
    left: 0;
  }

  .intl-tel-input.separate-dial-code .selected-flag {
    background-color: rgba(0, 0, 0, 0.05);
    display: table;
  }

  .intl-tel-input.separate-dial-code .selected-dial-code {
    display: table-cell;
    vertical-align: middle;
    padding-left: 28px;
  }

  .intl-tel-input.separate-dial-code.iti-sdc-2 input,
  .intl-tel-input.separate-dial-code.iti-sdc-2 input[type='tel'],
  .intl-tel-input.separate-dial-code.iti-sdc-2 input[type='text'] {
    padding-left: 66px;
  }

  .intl-tel-input.separate-dial-code.iti-sdc-2 .selected-flag {
    width: 60px;
  }

  .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-2 input,
  .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-2 input[type='tel'],
  .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-2 input[type='text'] {
    padding-left: 76px;
  }

  .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-2 .selected-flag {
    width: 70px;
  }

  .intl-tel-input.separate-dial-code.iti-sdc-3 input,
  .intl-tel-input.separate-dial-code.iti-sdc-3 input[type='tel'],
  .intl-tel-input.separate-dial-code.iti-sdc-3 input[type='text'] {
    padding-left: 74px;
  }

  .intl-tel-input.separate-dial-code.iti-sdc-3 .selected-flag {
    width: 68px;
  }

  .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 input,
  .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 input[type='tel'],
  .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 input[type='text'] {
    padding-left: 84px;
  }

  .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 .selected-flag {
    width: 78px;
  }

  .intl-tel-input.separate-dial-code.iti-sdc-4 input,
  .intl-tel-input.separate-dial-code.iti-sdc-4 input[type='tel'],
  .intl-tel-input.separate-dial-code.iti-sdc-4 input[type='text'] {
    padding-left: 82px;
  }

  .intl-tel-input.separate-dial-code.iti-sdc-4 .selected-flag {
    width: 76px;
  }

  .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-4 input,
  .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-4 input[type='tel'],
  .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-4 input[type='text'] {
    padding-left: 92px;
  }

  .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-4 .selected-flag {
    width: 86px;
  }

  .intl-tel-input.separate-dial-code.iti-sdc-5 input,
  .intl-tel-input.separate-dial-code.iti-sdc-5 input[type='tel'],
  .intl-tel-input.separate-dial-code.iti-sdc-5 input[type='text'] {
    padding-left: 90px;
  }

  .intl-tel-input.separate-dial-code.iti-sdc-5 .selected-flag {
    width: 84px;
  }

  .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-5 input,
  .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-5 input[type='tel'],
  .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-5 input[type='text'] {
    padding-left: 100px;
  }

  .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-5 .selected-flag {
    width: 94px;
  }

  .intl-tel-input.iti-container {
    position: absolute;
    top: -1000px;
    left: -1000px;
    z-index: 1060;
    padding: 1px;
  }

  .intl-tel-input.iti-container:hover {
    cursor: pointer;
  }

  .iti-mobile .intl-tel-input.iti-container {
    top: 30px;
    bottom: 30px;
    left: 30px;
    right: 30px;
    position: fixed;
  }

  .iti-mobile .intl-tel-input .country-list {
    max-height: 100%;
    width: 100%;
  }

  .iti-mobile .intl-tel-input .country-list .country {
    padding: 10px 10px;
    line-height: 1.5em;
  }

  @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min--moz-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2 / 1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
  }

  .iti-flag {
    img {
      height: 16px;
      width: 24px;
      border-radius: 2px;
    }
  }

  .iti-flag.ro {
    height: 14px;
    background-position: -4251px 0;
  }

  @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min--moz-device-pixel-ratio: 2),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
  }

  .col-xs-5ths,
  .col-sm-5ths,
  .col-md-5ths,
  .col-lg-5ths {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }

  .col-xs-5ths {
    width: 20%;
    float: left;
  }

  *:focus {
    outline: none;
  }

  a {
    color: #515769;
    text-decoration: none !important;
    position: relative;
  }

  a:hover {
    color: #515769;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: 'Titillium Web', sans-serif;
    color: #515769;
    font-size: 16px;
  }

  p {
    font-size: 16px;
  }

  header {
    border-bottom: 1px solid #e6e6e6;
  }

  body.menu-active header {
    border: 0;
  }

  :focus {
    outline: 0;
  }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }

  body {
    line-height: 26px;
  }

  ol,
  ul {
    list-style: none;
  }

  article ul {
    list-style: disc;
  }

  blockquote,
  q {
    quotes: none;
  }

  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  input[type='search'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }

  textarea {
    overflow: auto;
    vertical-align: top;
    resize: vertical;
  }

  audio,
  canvas,
  video {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    max-width: 100%;
  }

  audio:not([controls]) {
    display: none;
    height: 0;
  }

  [hidden] {
    display: none;
  }

  html {
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
  }

  a:focus {
    outline: 0;
  }

  a:active,
  a:hover {
    outline: 0;
  }

  img {
    border: 0;
    -ms-interpolation-mode: bicubic;
    max-width: 100%;
  }

  figure {
    margin: 0;
  }

  form {
    margin: 0;
  }

  fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
  }

  legend {
    border: 0;
    padding: 0;
    white-space: normal;
    *margin-left: -7px;
  }

  button,
  input,
  select,
  textarea {
    font-size: 100%;
    margin: 0;
    vertical-align: baseline;
    *vertical-align: middle;
  }

  button,
  input {
    line-height: normal;
  }

  button,
  select {
    text-transform: none;
  }

  button,
  html input[type='button'],
  input[type='reset'],
  input[type='submit'] {
    -webkit-appearance: button;
    cursor: pointer;
    *overflow: visible;
  }

  button[disabled],
  html input[disabled] {
    cursor: default;
  }

  input[type='checkbox'],
  input[type='radio'] {
    box-sizing: border-box;
    padding: 0;
  }

  input[type='search'] {
    -webkit-appearance: textfield;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
  }

  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  button::-moz-focus-inner,
  input::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  textarea {
    overflow: auto;
    vertical-align: top;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  html,
  button,
  input,
  select,
  textarea {
    color: #222;
  }

  ::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
  }

  ::selection {
    background: #b3d4fc;
    text-shadow: none;
  }

  img {
    vertical-align: middle;
  }

  fieldset {
    border: 0;
    margin: 0;
    padding: 0;
  }

  textarea {
    resize: vertical;
  }

  .chromeframe {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
  }

  .container {
    //max-width: 1230px;
    width: 100%;
  }

  .custom-form {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    background: white;
  }

  .form-content {
  }

  .form-content-content {
    max-width: 600px;
    padding: 15px;
    margin: 0 auto;
  }

  .close-button-wrapper {
    background: #fff;
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    z-index: 9999;
    padding: 8px;
    box-shadow: 0px 20px 70px -20px #00000047;
  }

  .close-button {
    padding: 8px;
    cursor: pointer;
    border-radius: 50%;
  }

  .contact .form {
    background: #fff;
    box-sizing: border-box;
  }

  .contact .form input {
    //width: 100%;
    box-sizing: border-box;
  }

  .contact .form textarea {
    width: 100%;
    box-sizing: border-box;
    height: 100px;
  }

  .contact .form .wpcf7-submit {
    width: 100%;
    box-sizing: border-box;
    background: #00c65e;
    border-radius: 5px;
  }

  .contact .wrap_info_box {
    width: 100%;
    margin-bottom: 10px;
    box-sizing: border-box;
    position: relative;
  }

  .form input[type='text'],
  .form input[type='number'],
  .form input[type='email'],
  .form select,
  .form textarea {
    background-color: #fff;
    width: 100% !important;
    border: 1px solid #eee;
    border-radius: 8px;
    padding: 10px 15px;
    margin: 0 0 10px;
    color: #000;
  }

  input[readonly] {
    cursor: not-allowed;
    color: #989898 !important;
    background-color: #eee !important;
  }

  .form .intl-tel-input input[type='text'] {
    padding: 10px 10px 10px 50px;
  }

  .form > p {
    margin: 0;
  }

  .form .wpcf7-list-item-label {
    font-size: 20px;
    line-height: 1.2;
    padding-left: 10px;
    padding: 0 0 0 10px;
    margin-bottom: 5px;
  }

  .form > *:nth-child(12) {
    text-align: center;
    padding: 30px 0 0 64px;
  }

  .form select,
  .form::placeholder {
    color: #606060 !important;
  }

  .form select[disabled],
  .form input[readonly='readonly'] {
    background-color: rgb(235, 235, 228, 0.5);
    color: rgb(84, 84, 84, 0.5);
  }

  .form h4 {
    font-size: 19px;
    line-height: 1.4;
    color: #10069f;
    margin: 15px 0 30px;
  }

  .edit-fields p {
    margin: 0;
  }

  .input-custom-wrapper input {
  }
  .edit-fields {
    border: 0;
    border-radius: 8px;
    line-height: 1;
    font-weight: 600;
    transition: all 0.2s;
    text-decoration: none;
    background-color: #147bd1 !important;
    color: #fff;
    width: auto;
    cursor: pointer;
  }

  .edit-fields:hover {
    background-color: #00c65e;
    color: #fff;
  }

  .form label {
    font-weight: 400 !important;
    font-size: 16px;
  }

  .form input[type='submit'] {
    display: inline-block;
    padding: 16px 24px;
    margin: 10px auto;
    border: 0;
    border-radius: 8px;
    font-size: 18px;
    line-height: 1;
    text-transform: uppercase;
    font-weight: 600;
    transition: all 0.2s;
    text-decoration: none;
    background-color: #00c65e;
    color: #fff;
    width: auto !important;
  }

  .form input[type='submit']:hover {
    background-color: #147bd1;
  }

  .form input[type='checkbox'] {
    margin-top: 8px;
  }

  .form .wpcf7-checkbox .wpcf7-list-item {
    margin: 0;
  }

  .form .wpcf7-list-item-label {
    font-size: 16px;
    padding-left: 5px;
  }

  .tel-field .wrapper-tel {
    display: flex;
    flex-direction: row;
    /* flex-wrap: nowrap; */
    width: 100%;
  }

  .tel-field p span[data-name='telefon_prefix'] {
    flex: 1 1 auto;
    width: 120px;
    margin: 0 10px 0 0;
    max-width: 120px;
  }

  #telefon_prefix {
    border-radius: 12px 0px 0px 12px;
  }

  #phone {
    border-radius: 0px 12px 12px 0px;
  }

  .tel-field p span[data-name='telefon'] {
    flex: 0 1 auto;
    max-width: calc(100% - 120px);
    width: 100%;
  }

  .wrapper-form {
    margin: 0px auto;
    display: flex;
    max-width: 400px;
    flex-direction: column;
  }

  .logo-custom {
    margin: 0 auto 30px;
    max-width: 90%;
    display: table;
    text-align: center;
  }

  .form-info {
    font-weight: 300;
    color: #474747 !important;

    b {
      color: #000;
    }
  }
}
