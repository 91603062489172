

.drop-title {
  color: #444;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  transition: color .2s ease-in-out;
}
input[type=file] {
  padding:13px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 2px dashed #dedcdc;
  color: grey;
  cursor: pointer;
  transition: .2s ease-in-out, border .2s ease-in-out;
}

.textField{
  padding: 13px;
}

input[type=file]::file-selector-button {
  margin-right: 20px;
  border: none;
  background: #3699FF;
  padding: 8px 20px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: .2s ease-in-out;
}

input[type=file]::file-selector-button:hover {
  background: #187DE4;
}

.loader {
      width: 15px;
      height: 15px;
      margin-right: 6px;
      margin-top: 2px;
      border-radius: 50%;
      position: relative;
      animation: rotate 1s linear infinite
    }
    .loader::before {
      content: "";
      box-sizing: border-box;
      position: absolute;
      inset: 0px;
      border-radius: 50%;
      border: 2px solid #FFF;
      animation: prixClipFix 2s linear infinite ;
    }

    @keyframes rotate {
      100%   {transform: rotate(360deg)}
    }

    @keyframes prixClipFix {
        0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
        25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
        50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
        75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
        100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
    }

.documentContainer{
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.textDoc{
  overflow: hidden;
  text-overflow: ellipsis;
  flex:1;
  margin-top:10px;
  white-space: nowrap;
}