@font-face {
  font-family: 'DIN 1451 Std Engschrift';
  font-style: normal;
  font-weight: normal;
  src: local('DIN 1451 Std Engschrift'), url('./fonts/DINEngschriftStd.woff') format('woff');
}

@font-face {
  font-family: 'DIN 1451 Std Mittelschrift';
  font-style: normal;
  font-weight: normal;
  src: local('DIN 1451 Std Mittelschrift'), url('./fonts/DINMittelschriftStd.woff') format('woff');
}

.transport-table th {
  white-space: nowrap;
}

.transport-table tr:nth-child(even) {
  background: #f6f6f6;
}

.transport-table:hover tr {
}
.transport-table td:first-child {
  padding-left: 16px !important;
}
.transport-table td:last-child {
  padding-right: 16px !important;
}

.clickable:hover {
  cursor: pointer;
}

.transport-table tr:hover {
}

.shift-wrapper {


  
}
.shift-wrapper label {
  display: block;
  font-size: 12px;
  margin-bottom: -2px;
  line-height: 14px;
}
.shift-wrapper p {
  font-weight: bold;
  margin-bottom: 8px;
}

.selectable-hover-card p {
  user-select: text;
}

.hover-card-item label {
  font-weight: 600;
}
.hover-card-service {
  float: right;
  margin-right: -4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f2f2f2;
  border-radius: 4px;
}
.hover-card-service img {
  width: 100px;
  margin-top: 10px;
}
.hover-card-item .startHour {
  background: #009ef7;
  padding: 0px 5px;
  border-radius: 4px;
  color: #fff;
  margin-left: 8px;
}
.hover-card-item .endHour {
  background: #e92828;
  padding: 0px 5px;
  border-radius: 4px;
  color: #fff;
  margin-left: 8px;
}

.rmdp-mobile .rmdp-calendar,
.rmdp-wrapper,
.right {
  width: 100% !important;
}

.rmdp-wrapper {
  margin-bottom: -16px !important;
  border-radius: 0 !important
}
.rmdp-panel-body li {
  padding: 8px !important;
  box-shadow: none !important;
  background: #fbfbfb !important;
  border: 1px solid #eee !important;
}
.rmdp-panel-body span{
  color:#000;
  font-size: 12px !important;
  font-weight: 300 !important;
}
.rmdp-panel-body button{
  right: 8px !important;
}

.rmdp-time-picker input{
  padding: 4px 0px !important;
}
/* DEMO */
/* th {
  position:relative;
}

th:hover::after, .ff-fix:hover::after {
  background-color: #ffa;
  content:'\00a0';
  height: 10000px;
  left: 0;
  position: absolute;
  top: -5000px;
  width: 100%;
  z-index: -1;
} */

.right-shadow {
  box-shadow: 20px 0px 30px -20px rgb(0 0 0 / 15%);
  border-right: 1px solid #e9e9e9 !important;
}

.szh-menu__item {
  padding: 6px 16px;
  font-size: 13px;
  font-weight: 400;
}
.szh-menu__item span {
  margin-right: 16px;
}

.transport-item-content {
  color: white;
  height: 55px;
  background: #009ef7;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.transport-item-title {
  font-weight: 500;
  margin-bottom: -4px;
}

#unassignedTable .transport-item {
  min-width: 130px;
  max-width: 130px;
  vertical-align: bottom;
}
.transport-item {
  min-width: 130px;
  max-width: 130px;
  outline: 0.1px solid #00000008;
}

.transport-item-date {
  font-size: 11px;
  opacity: 0.7;
  font-weight: 300;
}
.transport-item-hour {
  font-size: 11px;
  opacity: 0.7;
  margin-top: -4px;
  font-weight: 300;
}

.sticky-table-table {
  width: 100%;
}
/**
  Move button under select dropdown.
*/
.input-group button {
  z-index: 0 !important;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ddd;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(77, 77, 77);
}

/* dimensions */
.table-alt-scroll ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
.table-alt-scroll ::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

/* Handle */
.table-alt-scroll ::-webkit-scrollbar-thumb {
  background: #ddd !important;
}

/* Handle on hover */
.table-alt-scroll ::-webkit-scrollbar-thumb:hover {
  background: rgb(77, 77, 77) !important;
}

.assignment-small-card {
  background: #f2f2f2;
  padding: 8px;
  flex: 1 1;
  border-radius: 5px;
  height: 56px;
  margin-bottom: 16px;
}

.custom-date-picker {
  width: 100%;
}

.custom-date-picker .rmdp-input {
  width: 100%;
  box-sizing: border-box;
  height: 38px;
  border-radius: 0px 0.475rem 0.475rem 0px;
  border: 1px solid #E4E6EF;  
}

.editable-label {
  margin-bottom: 0.5rem;
  font-size: 1.05rem;
  font-weight: 500;
  color: #3F4254;
  border: none;
}

.table > :not(:first-child){
  border-top: 1px solid #e9e9e9 !important;
}

.grid-table > :not(:first-child){
  border-left: 1px solid #e9e9e9 !important;
}

.grid-table > :last-child {
  border-right: 1px solid #e9e9e9 !important;
}

.grid-table {
  border-bottom: 1px solid #e9e9e9 !important;
}

.spin {
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}

.signatureModal .modal-dialog{
  max-width: 35vw !important;
  margin-top: 4%;
}

@media screen and (max-width:568px) {
  .signatureModal .modal-dialog {
      max-width: 90vw !important;
      margin-top: 4%;
    }
}

.resizeTable{
  font-size: 7px !important;
}

.loadingText {
  padding: 6px 16px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin-top: 10%;
}

.radio-label {
  color: #303030;
  font-size: 14px;
  font-weight: 400;
  margin-right: 7px;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
}

.radio-input {
  margin: 0;
  visibility: hidden;
}

.radio-input:checked+span {
  border: 2px solid #0095E8;
}

.radio-input:checked+span:after {
  opacity: 1;
}

.custom-radio {
  left: -8px;
  top:15%;
  cursor: pointer;
  width: 17px;
  height: 17px;
  border: 2px solid #9a9a9a;
  border-radius: 50%;
  display: inline-block;
  position: relative;
}

/* for inner filled circle */
.custom-radio::after {
  content: '';
  width: 11px;
  height: 11px;
  background: #0095E8;
  position: absolute;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.2s;
}
.alert {
  margin-left: 1.5%;
  width: 97% !important;
  height: auto;
  border-radius: 5px;
  font-family: sans-serif;
  font-weight: 400;
}

.alertText {
  text-align: center;
  font-size: 12px;
  white-space: pre-wrap;
}

.warning {
  background-color: #FDF7DF;
  border: 1px solid #FEEC6F;
  color: #C9971C;
}

/* Chrome, Safari, Edge, Opera */
.quantity-input::-webkit-outer-spin-button,
.quantity-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.quantity-input[type=number] {
  -moz-appearance: textfield;
}
