

.company-card {
  padding: 20px;
  width: 25%;
  background-color: #F5F8FA;
  height: 100%;
  object-fit: cover;
  transition: 0.5s;
  transform-origin: center;
  transform: perspective(800px) rotateY(25deg);
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 10px;
}

.option{
  font-size: larger;
}

.company-container {
  width: 100%;
  max-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.company-card-active{
  color:#009EF7
}

.company-container .company-card-active:hover {
  transform: perspective(800px) rotateY(0deg);
  background-color: #009EF7;
  color:white
}

.company-card-inactive {
  opacity: 0.3;
}

.company-card-active:hover .link {
  color: white
}